import {Monorail, RetryMiddleware} from '@shopify/monorail';

import {isProduction} from '~/utils/env';

export const producer = isProduction
  ? Monorail.createHttpProducer({
      production: true,
      middleware: [new RetryMiddleware(10, 200)],
    })
  : Monorail.createLogProducer({debugMode: false});
